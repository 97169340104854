<template>
  <b-card>
    <b-card-title>Policies</b-card-title>
    <b-card-sub-title>
      Guests will see these policies when they will search for properties. Policies should be written very clearly and specifically so the guests can understand easily.
    </b-card-sub-title>
    <div class="card-body">
      <!-- cancellation policy -->
      <b-row
        class="my-1 service-charge border-bottom"
      >
        <b-col
          md="l2"
        >
          <b-form-group
            label="Please write cancellation policy"
            label-for="cancellation-policy"
          >
            <quill-editor
              id="cancellation-policy"
              v-model="policyData.cancellation_policy"
            />
          </b-form-group>
        </b-col>
        <b-col
          md="l2"
        >
          <b-form-group
            label="Please write cancellation text"
            label-for="cancellation-text"
          >
            <quill-editor
              id="cancellation-text"
              v-model="policyData.cancellation_text"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <!-- refund policy -->
      <b-row
        class="my-1 service-charge border-bottom"
      >
        <b-col
          md="l2"
        >
          <b-form-group
            label="Refund Policy"
            label-for="refund-policy"
          >
            <quill-editor
              id="refund-policy"
              v-model="policyData.refund_policy"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <!--child picker policy-->
      <b-row
        class="my-1 service-charge border-bottom"
      >
        <b-col
          md="6"
        >
          <!-- pricing -->
          <b-form-group
            label="Pricing"
            label-for="pricing"
          >
            <b-input-group
              prepend="BDT"
            >
              <b-form-input
                id="pricing"
                v-model="policyData.child_pricing_policy"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col
          md="6"
        >
          <!-- pricker text -->
          <b-form-group
            label="Child Pikcer Text"
            label-for="picker-text"
          >
            <b-input-group>
              <b-form-input
                id="picker-text"
                v-model="policyData.child_picker_text"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <!-- text and infant pikcer text -->
      <b-row
        class="my-1 service-charge border-bottom"
      >
        <b-col
          md="12"
        >
          <!-- text -->
          <b-form-group
            label="Text"
            label-for="text"
          >
            <quill-editor
              id="text"
              v-model="policyData.child_policy_text"
            />
          </b-form-group>
        </b-col>
        <!-- picker text -->
        <b-col
          md="6"
        >
          <b-form-group
            label="Infant Picker Text"
            label-for="infant-text"
          >
            <b-input-group>
              <b-form-input
                id="infant-text"
                v-model="policyData.infant_picker_text"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <!--Pax info-->
      <b-row
        class="my-1 service-charge border-bottom"
      >
        <b-col
          md="6"
        >
          <!-- min pax-->
          <b-form-group
            label="Min Pax"
            label-for="min-pax"
          >
            <b-input-group>
              <b-form-input
                id="min-pax"
                v-model="policyData.minimum_availability"
              />
            </b-input-group>
          </b-form-group>
          <!-- max pax-->
          <b-form-group
            label="Max Pax"
            label-for="max-pax"
          >
            <b-input-group>
              <b-form-input
                id="max-pax"
                v-model="policyData.maximum_availability"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <!-- starting price -->
        <b-col
          md="6"
        >
          <b-form-group
            label="Price"
            label-for="starting-price"
          >
            <b-input-group
              prepend="BDT"
            >
              <b-form-input
                id="starting-price"
                v-model="policyData.starting_price"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <!-- Submit -->
        <b-row
          class="my-1 service-charge ml-auto"
        >
          <div class="ml-auto">
            <b-button
              variant="primary"
              :disabled="isBtnLoading"
              @click="updatePolicyData()"
            >
              <b-spinner
                v-if="isBtnLoading"
                small
              />
              Submit
            </b-button>
          </div>
        </b-row>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import { quillEditor } from 'vue-quill-editor'
import api from '@/libs/api'
import helper from '@/mixins/helper'

export default {
  name: 'Policies',
  components: {
    quillEditor,
  },
  mixins: [helper],
  data() {
    return {
      content: '',
      policyData: {
        cancellation_policy: '',
        cancellation_text: '',
        refund_policy: '',
        child_policy_text: '',
        child_pricing_policy: '',
        child_picker_text: '',
        infant_picker_text: '',
        maximum_availability: '',
        starting_price: '',
      },
      isBtnLoading: false,
    }
  },
  mounted() {
    this.getPolicyData()
  },

  methods: {
    getPolicyData() {
      api.getData(`tour/admin_services/tour/${this.$route.params.id}`, true)
        .then(response => {
          if (response.data.status) {
            const responseData = response.data.result
            const policyKeys = [
              'cancellation_policy', 'cancellation_text',
              'refund_policy', 'child_pricing_policy', 'child_policy_text', 'child_picker_text',
              'infant_picker_text', 'maximum_availability', 'minimum_availability', 'starting_price',
            ]
            policyKeys.forEach(key => {
              this.policyData[key] = responseData[key]
            })
          }
        }).catch().finally(() => {
        })
    },
    updatePolicyData() {
      this.isBtnLoading = true
      api.updateData(`tour/admin_services/tour/${this.$route.params.id}/tour_update/`, true, this.policyData).then(response => {
        if (response.data.status) {
          this.notificationMessage('success', 'CheckIcon', 'Success', 'policy updated successfully')
          this.getPolicyData()
        } else {
          this.notificationMessage('danger', 'Xicon', 'Error', JSON.stringify(response.data.error.message))
        }
      }).catch(() => {
        this.notificationMessage('danger', 'XIcon', 'Error', 'Sorry, something went wrong!')
      }).finally(() => {
        this.isBtnLoading = false
      })
    },
  },
}
</script>

<style lang="scss" >
@import '@/assets/scss/tour.scss';
</style>
